import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"

const prdbestData = import("./data/data.json");

const Img = () => <img className="cws" src="/img/cws-badge.png" data-cy="cta" onClick = {() => window.triggerInstall()} />;

export default function prdbest() {

  return(
    <HomepageLayout>
      <Helmet>

        <style type="text/css">{`
        body {
          background-color: #e5e5e5;
        }
        h1 {
          margin: 0 auto !important;
        }
        .trbm-module--logo {
          margin: 0px 0px 10px !important;
          width: 130px !important;
        }

        .trbm-module--trbm .trbm-module--steps {
          display: none;
        }

        .trbm-module--trbm button {
          background: #43ba1a;
          padding: 20px 50px;
        }

        .trbm-module--card {
          padding: 20px 20px 30px;
        }

        #ctadisclosuresearch-module--ctadisclaimersearch {
          padding: 0px 7%;
          padding-bottom: 0;
        }

        .trbm-module--trbm h2 {
          margin-top: 7px;
        }

    .trbm-module--trbm .trbm-module--bullets {
         padding-left: 0;
               }
               .cws {
                 width: 200px;
                 display: block;
                 margin: 10px auto;
                 cursor:pointer;
               }

               #ctadisclosure-module--ctadisclaimer {
                 padding: 0px 7%;
                 padding-bottom: 0;

               }

      `}
         </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      <Trbm data={prdbestData}><Img /><CtaDisclosureSearch data={prdbestData}></CtaDisclosureSearch></Trbm>
    </HomepageLayout>
  )
}
